/* -------------------------------------------------------------------------- */
/*                                Falcon Button                               */
/* -------------------------------------------------------------------------- */
/* prettier-ignore */
.text-align-center {
  text-align: center !important;
}

.text-align-end {
  text-align: end !important;
}

.float-right {
  float: right !important;
}

.hide {
  display: none !important;
}

.always-on-top {
  z-index: 999999999999;
}

.bg-ai {
  --falcon-bg-opacity: 1;
  background: linear-gradient(to top right, rgba(218, 20, 70, 0.09), rgba(53, 104, 221, 0.18));
}

.ce-project-list-dropdown {
  margin-right: 15px;
}

.ce_project_list_select_project_btn {
  max-width: 380px;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .ce_project_list_select_project_btn {
    max-width: 110px;
    font-size: 10px;
  }
  .ce_project_list_select_project_list {
    right: -5rem !important;
  }
}
.is_sortable_table .sort[data-sort] {
  white-space: nowrap;
  cursor: pointer;
}

.is_sortable_table .sort[data-sort]::after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi41MjU1NiAzLjVDNi45NDkzNyAzLjUgNy4xNjEyOCAzLjA2MjUgNi44NTUxOSAyLjc5MTY3TDMuODQxNCAwLjEyNUMzLjY1MzA0IC0wLjA0MTY2NjcgMy4zNDY5NiAtMC4wNDE2NjY3IDMuMTU4NTkgMC4xMjVMMC4xNDQ4MDkgMi43OTE2N0MtMC4xNjEyNzkgMy4wNjI1IDAuMDUwNjI3OSAzLjUgMC40NzQ0NDEgMy41SDYuNTI1NTZaIiBmaWxsPSIjQjZDMkQyIi8+CjxwYXRoIGQ9Ik0wLjQ3NDQ0MiA1LjVDMC4wNTA2MjgyIDUuNSAtMC4xNjEyNzkgNS45Mzc1IDAuMTQ0ODA5IDYuMjA4MzNMMy4xNTg1OSA4Ljg3NUMzLjM0Njk2IDkuMDQxNjcgMy42NTMwNCA5LjA0MTY3IDMuODQxNDEgOC44NzVMNi44NTUxOSA2LjIwODMzQzcuMTYxMjggNS45Mzc1IDYuOTQ5MzcgNS41IDYuNTI1NTYgNS41TDAuNDc0NDQyIDUuNVoiIGZpbGw9IiNCNkMyRDIiLz4KPC9zdmc+Cg==);
  margin-left: 0.25rem;
}

.is_sortable_table .sort[data-sort].desc::after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNyA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi41MjU1NiAzLjVDNi45NDkzNyAzLjUgNy4xNjEyOCAzLjA2MjUgNi44NTUxOSAyLjc5MTY3TDMuODQxNCAwLjEyNUMzLjY1MzA0IC0wLjA0MTY2NjcgMy4zNDY5NiAtMC4wNDE2NjY3IDMuMTU4NTkgMC4xMjVMMC4xNDQ4MDkgMi43OTE2N0MtMC4xNjEyNzkgMy4wNjI1IDAuMDUwNjI3OSAzLjUgMC40NzQ0NDEgMy41SDYuNTI1NTZaIiBmaWxsPSIjQjZDMkQyIi8+Cjwvc3ZnPgo=) !important;
  margin-top: -1px !important;
}

.is_sortable_table .sort[data-sort].asc::after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNyA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC40NzQ0NDIgMC41MDAwMDFDMC4wNTA2MjgyIDAuNTAwMDAxIC0wLjE2MTI3OSAwLjkzNzUgMC4xNDQ4MDkgMS4yMDgzM0wzLjE1ODU5IDMuODc1QzMuMzQ2OTYgNC4wNDE2NyAzLjY1MzA0IDQuMDQxNjcgMy44NDE0MSAzLjg3NUw2Ljg1NTE5IDEuMjA4MzNDNy4xNjEyOCAwLjkzNzUwMSA2Ljk0OTM3IDAuNTAwMDAxIDYuNTI1NTYgMC41MDAwMDFMMC40NzQ0NDIgMC41MDAwMDFaIiBmaWxsPSIjQjZDMkQyIi8+Cjwvc3ZnPgo=) !important;
  margin-top: -1px !important;
}

#ce-workflow-lead-modal .modal-content {
  max-height: 85vh;
  overflow: auto;
}

.lead-engine-div {
  border-left: 1px solid var(--falcon-border-color);
}

.ce-workflow-lead-engine-container {
  height: 80%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
}

@media (max-width: 991.98px) {
  .ce_profile {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
}

.lead-profile-dropdown-assign-action {
  max-height: 400px;
  overflow: auto;
}

.ce-lead-status-container-body {
  overflow: hidden;
}

.lead-status-credit-interaction-item.out-item {
  background-color: #cbe1ed;
}

.cc-instance-container {
  max-height: 65vh;
  overflow: auto;
  transition: height 0.25s ease-in-out;
}

.l_cc-instance-datetime-parsed_bubble {
  border-radius: 10px;
  font-weight: 600;
  width: fit-content;
  max-width: 90%;
}

.l_cc-instance-datetime-parsed_bubble.outgoing {
  color: inherit;
  color: #FFF;
}

.l_cc-appointment-updated_bubble {
  background: #27bcfd;
  color: #FFF;
}

.cc-history-log {
  overflow: hidden;
}

.cc-reminder-log {
  background: rgba(231, 216, 255, 0.8);
  border: 2px dotted rgba(32, 32, 32, 0.6705882353);
  border-radius: 10px;
}

.cc-action-history-highlight {
  background: #e7d8ff;
  border-radius: 5px;
  padding: 1px 5px;
}

.cc-history-log-header {
  background: #f1f1f1;
  border-radius: 5px;
}

.l_cc-logs-outgoing_history_action {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ce_chat {
  max-height: none;
  padding: 20px 40px;
}

#lead_crm_table_body .ce_lead_stage_btn_group {
  max-width: 110px;
}
#lead_crm_table_body .ce_lead_stage_btn_group span.badge, #lead_crm_table_body .owner span.badge {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
#lead_crm_table_body .ce_lead_stage_btn_group .ce_wf_action_indicator {
  position: absolute;
  right: -20px;
}
#lead_crm_table_body .ce_crm_list_lead_activity_note {
  max-width: 110px;
  max-height: 40px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

#lead_crm_table table.table {
  min-height: 50vh;
}

[for=l_crm_list_radio_type-workflow_stage] {
  border-radius: 0;
}

.btn-load-more-workflow:hover, .btn-load-more-workflow:focus {
  background-color: var(--falcon-kanban-btn-add-hover-bg);
}

.kanban-column .bg-subtle-primary {
  color: var(--falcon-badge-subtle-primary-color);
  background-color: var(--falcon-badge-subtle-primary-background-color);
}
.kanban-column .bg-subtle-primary[href]:hover, .kanban-column .bg-subtle-primary[href]:focus {
  text-decoration: none;
  color: var(--falcon-badge-subtle-primary-color);
  background-color: var(--falcon-badge-subtle-primary-background-color);
}

.kanban-column .bg-subtle-secondary {
  color: var(--falcon-badge-subtle-secondary-color);
  background-color: var(--falcon-badge-subtle-secondary-background-color);
}
.kanban-column .bg-subtle-secondary[href]:hover, .kanban-column .bg-subtle-secondary[href]:focus {
  text-decoration: none;
  color: var(--falcon-badge-subtle-secondary-color);
  background-color: var(--falcon-badge-subtle-secondary-background-color);
}

.kanban-column .bg-subtle-success {
  color: var(--falcon-badge-subtle-success-color);
  background-color: var(--falcon-badge-subtle-success-background-color);
}
.kanban-column .bg-subtle-success[href]:hover, .kanban-column .bg-subtle-success[href]:focus {
  text-decoration: none;
  color: var(--falcon-badge-subtle-success-color);
  background-color: var(--falcon-badge-subtle-success-background-color);
}

.kanban-column .bg-subtle-info {
  color: var(--falcon-badge-subtle-info-color);
  background-color: var(--falcon-badge-subtle-info-background-color);
}
.kanban-column .bg-subtle-info[href]:hover, .kanban-column .bg-subtle-info[href]:focus {
  text-decoration: none;
  color: var(--falcon-badge-subtle-info-color);
  background-color: var(--falcon-badge-subtle-info-background-color);
}

.kanban-column .bg-subtle-warning {
  color: var(--falcon-badge-subtle-warning-color);
  background-color: var(--falcon-badge-subtle-warning-background-color);
}
.kanban-column .bg-subtle-warning[href]:hover, .kanban-column .bg-subtle-warning[href]:focus {
  text-decoration: none;
  color: var(--falcon-badge-subtle-warning-color);
  background-color: var(--falcon-badge-subtle-warning-background-color);
}

.kanban-column .bg-subtle-danger {
  color: var(--falcon-badge-subtle-danger-color);
  background-color: var(--falcon-badge-subtle-danger-background-color);
}
.kanban-column .bg-subtle-danger[href]:hover, .kanban-column .bg-subtle-danger[href]:focus {
  text-decoration: none;
  color: var(--falcon-badge-subtle-danger-color);
  background-color: var(--falcon-badge-subtle-danger-background-color);
}

.kanban-column .bg-subtle-light {
  color: var(--falcon-badge-subtle-light-color);
  background-color: var(--falcon-badge-subtle-light-background-color);
}
.kanban-column .bg-subtle-light[href]:hover, .kanban-column .bg-subtle-light[href]:focus {
  text-decoration: none;
  color: var(--falcon-badge-subtle-light-color);
  background-color: var(--falcon-badge-subtle-light-background-color);
}

.kanban-column .bg-subtle-dark {
  color: var(--falcon-badge-subtle-dark-color);
  background-color: var(--falcon-badge-subtle-dark-background-color);
}
.kanban-column .bg-subtle-dark[href]:hover, .kanban-column .bg-subtle-dark[href]:focus {
  text-decoration: none;
  color: var(--falcon-badge-subtle-dark-color);
  background-color: var(--falcon-badge-subtle-dark-background-color);
}

[data-bs-theme=dark] .cc-history-log-header {
  background-color: rgba(241, 241, 241, 0.1);
}
[data-bs-theme=dark] .cc-action-history-highlight {
  background: #191919;
}
[data-bs-theme=dark] .lead-status-credit-interaction-item.out-item {
  background: #191919;
}
[data-bs-theme=dark] .cc-reminder-log {
  background: rgba(141, 78, 242, 0.5);
}

.ce_automation_scenario_step_hr {
  width: 80%;
  height: 1rem;
}
.ce_automation_scenario_step_hr span {
  font-size: 1rem;
  background: inherit;
}

.ce_automation_scenario_step_inner {
  overflow: hidden;
  border-radius: 0.25rem;
}

.ce_convert_assist_container {
  border-radius: 0.25rem;
}

@media (width: 515px), (width: 512px) {
  .navbar.navbar-top .navbar-toggler-humburger-icon,
.navbar.navbar-top ul.navbar-nav-icons {
    display: none !important;
  }
  .navbar.navbar-top ul.ce-search-ul {
    display: inherit !important;
  }
  .navbar.navbar-top .navbar-brand img {
    width: 120px;
  }
}