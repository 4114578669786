@import "../../node_modules/bootstrap/scss/_functions";
@import "theme/functions";

@import "../../node_modules/bootstrap/scss/mixins";
@import "theme/mixins";

@import "user-variables";
@import 'theme/colors';
@import "theme/variables";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";

// UTILS
.text-align-center {
    text-align: center !important;
}
.text-align-end {
    text-align: end !important;
}
.float-right {
    float: right !important;
}
.hide {
    display: none !important;
}
.always-on-top {
    z-index: 999999999999;
}
.bg-ai {
    --falcon-bg-opacity: 1;
    background: linear-gradient(to top right, rgb(218 20 70 / 9%), rgb(53 104 221 / 18%));
}

// LAYOUT
.ce-project-list-dropdown {
    margin-right: 15px;
}
.ce_project_list_select_project_btn {
    max-width: 380px;
    text-overflow: ellipsis;
    overflow: hidden;
}
@media only screen and (max-width: 768px) {
    .ce_project_list_select_project_btn {
        max-width: 110px;
        font-size: 10px;
    }
    .ce_project_list_select_project_list {
        right: -5rem !important;
    }
}

// TABLE SORTS
.is_sortable_table .sort[data-sort] {
    white-space: nowrap;
    cursor: pointer;
}
.is_sortable_table .sort[data-sort]::after {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi41MjU1NiAzLjVDNi45NDkzNyAzLjUgNy4xNjEyOCAzLjA2MjUgNi44NTUxOSAyLjc5MTY3TDMuODQxNCAwLjEyNUMzLjY1MzA0IC0wLjA0MTY2NjcgMy4zNDY5NiAtMC4wNDE2NjY3IDMuMTU4NTkgMC4xMjVMMC4xNDQ4MDkgMi43OTE2N0MtMC4xNjEyNzkgMy4wNjI1IDAuMDUwNjI3OSAzLjUgMC40NzQ0NDEgMy41SDYuNTI1NTZaIiBmaWxsPSIjQjZDMkQyIi8+CjxwYXRoIGQ9Ik0wLjQ3NDQ0MiA1LjVDMC4wNTA2MjgyIDUuNSAtMC4xNjEyNzkgNS45Mzc1IDAuMTQ0ODA5IDYuMjA4MzNMMy4xNTg1OSA4Ljg3NUMzLjM0Njk2IDkuMDQxNjcgMy42NTMwNCA5LjA0MTY3IDMuODQxNDEgOC44NzVMNi44NTUxOSA2LjIwODMzQzcuMTYxMjggNS45Mzc1IDYuOTQ5MzcgNS41IDYuNTI1NTYgNS41TDAuNDc0NDQyIDUuNVoiIGZpbGw9IiNCNkMyRDIiLz4KPC9zdmc+Cg==);
    margin-left: 0.25rem;
}
.is_sortable_table .sort[data-sort].desc::after {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNyA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi41MjU1NiAzLjVDNi45NDkzNyAzLjUgNy4xNjEyOCAzLjA2MjUgNi44NTUxOSAyLjc5MTY3TDMuODQxNCAwLjEyNUMzLjY1MzA0IC0wLjA0MTY2NjcgMy4zNDY5NiAtMC4wNDE2NjY3IDMuMTU4NTkgMC4xMjVMMC4xNDQ4MDkgMi43OTE2N0MtMC4xNjEyNzkgMy4wNjI1IDAuMDUwNjI3OSAzLjUgMC40NzQ0NDEgMy41SDYuNTI1NTZaIiBmaWxsPSIjQjZDMkQyIi8+Cjwvc3ZnPgo=) !important;
    margin-top: -1px !important;
}
.is_sortable_table .sort[data-sort].asc::after {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNyA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC40NzQ0NDIgMC41MDAwMDFDMC4wNTA2MjgyIDAuNTAwMDAxIC0wLjE2MTI3OSAwLjkzNzUgMC4xNDQ4MDkgMS4yMDgzM0wzLjE1ODU5IDMuODc1QzMuMzQ2OTYgNC4wNDE2NyAzLjY1MzA0IDQuMDQxNjcgMy44NDE0MSAzLjg3NUw2Ljg1NTE5IDEuMjA4MzNDNy4xNjEyOCAwLjkzNzUwMSA2Ljk0OTM3IDAuNTAwMDAxIDYuNTI1NTYgMC41MDAwMDFMMC40NzQ0NDIgMC41MDAwMDFaIiBmaWxsPSIjQjZDMkQyIi8+Cjwvc3ZnPgo=) !important;
    margin-top: -1px !important;
}


#ce-workflow-lead-modal .modal-content {
    max-height: 85vh;
    overflow: auto;
}

// #ce-workflow-lead-modal .modal-content::-webkit-scrollbar {
//     // background-color: #2b2b2b;
//     // color: #f2f2f2
// }
// #ce-workflow-lead-modal .modal-content::-webkit-scrollbar-track {
//     // background-color: transparent;
// }

.lead-engine-div {
    border-left: 1px solid var(--falcon-border-color);;
}

.ce-workflow-lead-engine-container {
    height: 80%;
    background: rgba(0,0,0,0.1);
    border-radius: 5px;
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.15);
}

// CE PROFILE
.ce_profile {
    @include media-breakpoint-down(lg) {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }
}

.lead-profile-dropdown-assign-action {
    max-height: 400px;
    overflow: auto;
}

// LEAD CREDIT STATUS
.ce-lead-status-container-body {
    overflow: hidden;
}
.lead-status-credit-interaction-item.out-item {
    background-color: #cbe1ed;
}


// ENGINE INTERACTIONS
.cc-instance-container {
    max-height: 65vh;
    overflow: auto;
    transition: height 0.25s ease-in-out;
}

.l_cc-instance-datetime-parsed_bubble {
    // background: #1982FC;
    border-radius: 10px;
    // color: #FFF;
    font-weight: 600;
    width: fit-content;
    max-width: 90%; // set no wider than 90%;   
}
.l_cc-instance-datetime-parsed_bubble.outgoing {
    color: inherit;
    // background: #1982FC;
    color: #FFF;
}
.l_cc-appointment-updated_bubble {
    background: #27bcfd;
    color: #FFF;
}

.cc-history-log {
    // border-radius: 10px;
    overflow: hidden;
    // box-shadow: 0px 0px 8px rgba(0,0,0,0.1);
    // border: 1px solid #bbbaba;
}
// .cc-history-interstitial {
//     text-align: center;
//     font-size: 12px;
// }
.cc-reminder-log {
    background: #e7d8ffcc;
    border: 2px dotted #202020ab;
    border-radius: 10px;
}

.cc-action-history-highlight {
    background: #e7d8ff;
    border-radius: 5px;
    padding: 1px 5px;
}

.cc-history-log-header {
    background: #f1f1f1;
    border-radius: 5px;
}
.l_cc-logs-outgoing_history_action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

// CONVO VIEW
.ce_chat {
    max-height: none;
    padding: 20px 40px;
}


// LEAD CRM
#lead_crm_table_body {
    .ce_lead_stage_btn_group {
        max-width: 110px;
    }
    .ce_lead_stage_btn_group span.badge, .owner span.badge {
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .ce_lead_stage_btn_group .ce_wf_action_indicator {
        position: absolute;
        right: -20px;
    }

    .ce_crm_list_lead_activity_note {
        max-width: 110px;
        max-height: 40px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
};
#lead_crm_table {
    table.table {
        min-height: 50vh;
    }
}
[for="l_crm_list_radio_type-workflow_stage"]{
    border-radius: 0
}

// WORKFLOW VIEW
.btn-load-more-workflow {
    @include hover-focus {
    background-color: var(--#{$prefix}kanban-btn-add-hover-bg);
    }
}
@mixin bg-subtle-variant($color) {
    color: var(--#{$prefix}badge-subtle-#{$color}-color);
    background-color: var(--#{$prefix}badge-subtle-#{$color}-background-color);
    &[href] {
      @include hover-focus {
        text-decoration: none;
        color: var(--#{$prefix}badge-subtle-#{$color}-color);
        background-color: var(--#{$prefix}badge-subtle-#{$color}-background-color);
      }
    }
  }
  
@each $color, $value in $theme-colors {
    .kanban-column .bg-subtle-#{$color} {
        @include bg-subtle-variant($color)
    }
}



// DARK MODE ADJUSTMENTS
[data-bs-theme=dark] {

    .l_cc-instance-datetime-parsed_bubble.outgoing {
        // background-color: #31bb36 !important;
    }
    .cc-history-log-header {
        background-color: rgba(241, 241, 241, 0.1);
    }
    .cc-action-history-highlight {
        background: #191919;
    }
    .lead-status-credit-interaction-item.out-item {
        background: #191919;
    }
    .cc-reminder-log {
        background: rgb(141 78 242 / 50%);
    }
}


// AUTOMATION SCENARIOS
.ce_automation_scenario_step_hr {
    width: 80%;
    height: 1rem;
    span {
        font-size: 1rem;
        background: inherit;
    }
}
.ce_automation_scenario_step_inner {
    overflow: hidden;
    border-radius: 0.25rem;
}
.ce_convert_assist_container {
    border-radius: 0.25rem;
}


// CHROME EXTENSION
@media (width: 515px), (width: 512px) {
    // Navbar
    .navbar.navbar-top {
        .navbar-toggler-humburger-icon,
        ul.navbar-nav-icons {
            display: none !important;
        }
        ul.ce-search-ul {
            display: inherit !important;
        }
        .navbar-brand img {
            width: 120px;
        }
    }
}